import { useAuth } from "../provider/authProvider";
import CustomScheduler from "../components/calendar/calendarScheduler";
import { useUserPreference } from "./../utils/userPreferencesUtils/useUserPreferences";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IVocationDaysSearch } from "../interfaces/overview.interface";
import moment from "moment";
import {
  getPublicHolidays,
  searchVocationDays,
  getVocationDaysRequestType,
  getVocationDaysPaidLeaveRequestType,
} from "../api/overviewApi";
import { PersonAdd, BookmarkAdd, Info } from "@mui/icons-material";
import { Paper, Typography, Button, Tooltip } from "@mui/material";
import themeOptions from "../style/componentsStyle";
import AddNewRequestModal from "../modals/addRequestCalendar/addNewRequest";
import AddNewEmployee from "../modals/addRequestCalendar/addNewEmployee";

import {
  transformDataToEvents,
  transformDataToResources,
} from "../utils/dataTransformersForCalendar";

const Overview = () => {
  const { loggedUserId } = useAuth();
  const [userPreference, setUserPreference] = useUserPreference(loggedUserId);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [vocationDaysResponse, setVocationDaysResponse] = useState(null);
  const [publicHolidaysResponse, setPublicHolidaysResponse] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState<any>();
  const [selectedEventData, setSelectedEventData] = useState<any>();
  const [requestTypes, setRequestTypes] = useState<any>([]);
  const [paidLeaveTypes, setPaidLeaveTypes] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedRequestTypes = await getVocationDaysRequestType();
        const fetchedPaidLeaveTypes =
          await getVocationDaysPaidLeaveRequestType();

        setRequestTypes(fetchedRequestTypes);
        setPaidLeaveTypes(fetchedPaidLeaveTypes);
      } catch (error) {
        console.error("Failed to load data for the form:", error);
      }
    };

    fetchData();
  }, []);

  const resources = useMemo(() => {
    // Ensure vocationDaysResponse is not null before transforming
    return vocationDaysResponse
      ? transformDataToResources(vocationDaysResponse)
      : [];
  }, [vocationDaysResponse]);

  const events = useMemo(() => {
    // Ensure neither response is null before transforming
    return vocationDaysResponse && publicHolidaysResponse
      ? transformDataToEvents(vocationDaysResponse, publicHolidaysResponse)
      : [];
  }, [vocationDaysResponse, publicHolidaysResponse]);

  const [modalStates, setModalStates] = useState({
    addNewRequest: false,
    addNewEmployee: false,
  });

  const handleOpenModal = useCallback((modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));
  }, []); // Add dependencies if the function depends on any props or state

  const handleCloseModal = useCallback(
    (modalName: keyof typeof modalStates) => {
      setModalStates((prevState) => ({
        ...prevState,
        [modalName]: false,
      }));
    },
    []
  );

  // New state to manage the list of selected user IDs
  const [selectedUserIds, setSelectedUserIds] = useState<any[]>([]);

  // Initialization effect for user IDs
  useEffect(() => {
    const initialUserIds = userPreference?.selectedUserIds ?? [];
    if (loggedUserId && !initialUserIds.includes(loggedUserId)) {
      setSelectedUserIds([...initialUserIds, loggedUserId]);
    } else {
      setSelectedUserIds(initialUserIds);
    }
  }, [loggedUserId, userPreference?.selectedUserIds]);

  const handleDatesSet = (dateInfo: any) => {
    // Extract the start date of the current view
    const startDate = dateInfo.start;
    // Format and set the current month and year
    setSelectedMonth(startDate.getMonth() + 1); // JavaScript months are 0-indexed
    setSelectedYear(startDate.getFullYear());
  };

  const getEmployeeDetailsQuery = (): IVocationDaysSearch => {
    const employeeDetailsQuery: IVocationDaysSearch = {
      employeeId: [],
      month: null,
      year: null,
    };

    moment.locale("en");
    const month = moment().month(selectedMonth).format("M");
    employeeDetailsQuery.month = parseInt(month, 10);
    employeeDetailsQuery.year = Number(selectedYear);
    employeeDetailsQuery.employeeId = selectedUserIds;

    return employeeDetailsQuery;
  };

  const updateSelectedUsers = (userIds: any) => {
    setSelectedUserIds(userIds);
    setUserPreference({ ...userPreference, selectedUserIds: userIds });
  };

  // Data fetching effect
  const fetchAndSetData = useCallback(async () => {
    // Ensure month and year are set before proceeding
    if (!selectedMonth || !selectedYear || selectedUserIds.length === 0) return;

    const month = selectedMonth.toString();
    const year = Number(selectedYear);
    const filterPublicHolidays = { year, month };

    try {
      const vocationDaysRes = (await searchVocationDays(
        getEmployeeDetailsQuery()
      )) as any;
      const publicHolidaysRes = (await getPublicHolidays(
        filterPublicHolidays
      )) as any;

      // Transforming the data to a simpler format for the dropdown
      const tempSelectedEmployees = vocationDaysRes.data.map(
        (employee: any) => ({
          id: employee.employee.id,
          name: `${employee.employee.firstName} ${employee.employee.lastName}`,
        })
      );

      setVocationDaysResponse(vocationDaysRes.data);
      setPublicHolidaysResponse(publicHolidaysRes.data);
      setSelectedEmployees(tempSelectedEmployees);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Consider setting an error state here to provide feedback to the user
    }
  }, [selectedMonth, selectedYear, selectedUserIds.length]);

  useEffect(() => {
    fetchAndSetData();
  }, [fetchAndSetData]);

  const handleCalendarRequest = (event: any) => {
    console.log(event);
    const resources = event.getResources();

    setSelectedEventData({
      id: event.id,
      person: resources[0].id,
      requestType: event.extendedProps.requestType.id,
      startDate: event.start,
      endDate: event.extendedProps.originalEnd,
      note: event.extendedProps.note,
    });
    handleOpenModal("addNewRequest");
  };

  return (
    <div>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          margin: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          Employee overview
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button>
            <Tooltip title="Add new employee to list">
              <PersonAdd
                style={{
                  borderRadius: "50%",
                  alignItems: "center",
                  padding: "5px",
                }}
                sx={{
                  backgroundColor: themeOptions.palette.primary.main,
                  color: "white",
                }}
                onClick={() => handleOpenModal("addNewEmployee")}
              />
            </Tooltip>
          </Button>
          {/* <Button>
            <Tooltip title="Add request">
              <BookmarkAdd
                style={{
                  borderRadius: "50%",
                  alignItems: "center",
                  padding: "5px",
                  marginLeft: "5px",
                }}
                sx={{
                  backgroundColor: themeOptions.palette.primary.main,
                  color: "white",
                }}
                onClick={() => handleOpenModal("addNewRequest")}
              />
            </Tooltip>
          </Button> */}
        </div>
      </Paper>
      <CustomScheduler
        datesSet={handleDatesSet}
        calendarRequestClicked={handleCalendarRequest}
        resources={resources}
        events={events}
      />
      {modalStates.addNewEmployee && (
        <AddNewEmployee
          open={modalStates.addNewEmployee}
          handleClose={() => handleCloseModal("addNewEmployee")}
          onSaveSelectedEmployees={updateSelectedUsers}
          selectedUserIds={selectedUserIds}
        />
      )}
      {modalStates.addNewRequest && (
        <AddNewRequestModal
          open={modalStates.addNewRequest}
          handleClose={() => {
            handleCloseModal("addNewRequest");
            setSelectedEventData(undefined);
          }}
          onSuccess={fetchAndSetData}
          persons={selectedEmployees}
          requestData={selectedEventData}
          requestTypes={requestTypes}
          paidLeaveTypes={paidLeaveTypes}
        />
      )}
    </div>
  );
};

export default Overview;
